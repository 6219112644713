.App {
  text-align: center;
}

h1 {
  position: absolute;
  top: 0;
  text-align: center;
  width: 100%;
  left: 0;
  font-weight: 100;
  font-size: 48px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input {
  padding: 10px;
  width: 250px;
  font-size: 18px;
  outline: none;
  text-align: left;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid #232323;
}

::placeholder {
  color: #b1b1b1;
  opacity: 0.3;
}

.block {
  color: #f45d48;
  font-size: 94px;
  margin-bottom: 40px;
}

.block a {
  color: inherit;
  text-decoration: none;
}

.loading {
  font-weight: bold;
  font-size: 18px;
  height: 20px;
}

.footer {
  position: absolute;
  bottom: 20px;
  right: 20px;
  font-weight: 800;
  color: #f45d48;
}

@media (max-width: 520px) {
  h1 {
    font-size: 40px;
  }

  .block {
    font-size: 74px;
  }
}
